import "styles/pages/page-vote.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SectionTitle from "components/SectionTitle";
import Breadcrumbs from "components/Breadcrumbs";
import { Link, graphql } from "gatsby";
import ContestTile from "../components/ContestTile";
import Pagination from "../components/Pagination";

// This page shows all ongoing events in /glosowanie and all finished events in /inspiracje

const VoteListPage = ({ pageContext, data }) => {
   const seo = pageContext.seo;
   const listItems = data.allWpVote.nodes;

   return (
      <Layout>
         <Seo title={seo.title ? seo.title : pageContext.title} />
         <Breadcrumbs currentLocationLabel={pageContext.title} />
         <section className="vote-page">
            <div className="container">
               <SectionTitle title={pageContext.title} />
               <div className="row justify-content-center">
                  <div className="col-xl-10">
                     <div
                        className="vote-page__text"
                        dangerouslySetInnerHTML={{
                           __html: pageContext.data.votesListPage.desc,
                        }}
                     />
                     {pageContext.isInspirationPage !== true && (
                        <div className="center-element">
                           <Link to="/regulamin-glosowania" className="button">
                              Regulamin głosowania
                           </Link>
                        </div>
                     )}
                     <div className="vote-page__list">
                        {listItems.length === 0 || undefined ? (
                           <div className="vote-page__missing">
                              {pageContext.isInspirationPage !== true
                                 ? "Obecnie nie trwają żadne głosowania."
                                 : "Obecnie nie ma żadnych inspiracji."}
                           </div>
                        ) : (
                           listItems?.map((item, index) => {
                              let votesArr = pageContext.votes.filter(
                                 (vote) => {
                                    return vote.post === item.id;
                                 }
                              );
                              let votes = votesArr[0]
                                 ? votesArr[0].NumberOfVote
                                 : 0;
                              return (
                                 <ContestTile
                                    key={index}
                                    postId={item.id}
                                    votes={votes}
                                    category={
                                       item.votesCategory?.nodes[0]?.name
                                    }
                                    title={item.title}
                                    desc={item.singleVotePage?.shortDesc}
                                    dateStart={item.singleVotePage?.startDate}
                                    dateEnd={item.singleVotePage?.endDate}
                                    thumbnail={
                                       item.singleVotePage.mainImage?.sourceUrl
                                    }
                                    status={item.singleVotePage?.status}
                                    link={item.uri}
                                    categoryColor={
                                       item.votesCategory?.nodes[0]
                                          ?.votesCategory?.color
                                    }
                                 />
                              );
                           })
                        )}
                     </div>
                     {pageContext.numPages > 0 ? (
                        <Pagination {...pageContext} />
                     ) : (
                        ""
                     )}
                  </div>
               </div>
            </div>
         </section>
      </Layout>
   );
};

export const query = graphql`
   query ($limit: Int!, $skip: Int!, $filter: String!) {
      allWpVote(
         limit: $limit
         skip: $skip
         sort: { fields: date, order: DESC }
         filter: { singleVotePage: { status: { eq: $filter } } }
      ) {
         nodes {
            title
            uri
            id
            slug
            singleVotePage {
               status
               shortDesc
               mainImage {
                  sourceUrl
               }
               startDate
               endDate
            }
            votesCategory {
               nodes {
                  name
                  votesCategory {
                     color
                  }
               }
            }
         }
      }
   }
`;

export default VoteListPage;
