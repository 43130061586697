import "./contest-tile.scss";

import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import moment from "moment";

import VotePopup from "components/VotePopup";
import classNames from "classnames";

const ContestTile = ({
   postId,
   votes,
   category,
   title,
   desc,
   thumbnail,
   dateStart,
   dateEnd,
   link,
   categoryColor,
   status,
}) => {
   const [votesAmount, setVotesAmount] = useState(Number(votes));
   const [label, setLabel] = useState("głosów");

   const [isPopupVisible, setIsPopupVisible] = useState(false);
   const closePopup = () => setIsPopupVisible(false);

   const date_end_formatted = `${dateEnd} 12:00`;

   const date_today = moment();
   const date_start = moment(dateStart, "DD/MM/YYYY");
   const date_end = moment(date_end_formatted, "DD/MM/YYYY HH:mm");

   const is_between = date_today.isBetween(date_start, date_end);

   useEffect(() => {
      fetch(
         `https://malopolska-lokalnie.zenx.pl/wp-json/adreamvote/v1/get/${postId}`,
         {
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
            },
            method: "GET",
            cache: "no-cache",
            mode: "cors",
         }
      )
         .then((res) => res.json())
         .then((data) => {
            typeof data[0].NumberOfVote !== undefined
               ? setVotesAmount(Number(data[0].NumberOfVote))
               : setVotesAmount(0);
         })
         .catch((res) => {
            // console.log("Wystąpił błąd. Proszę spróbować ponownie.");
         });
      if (votesAmount === 1) {
         setLabel("głos");
      } else if (
         votesAmount % 10 === 2 ||
         votesAmount % 10 === 3 ||
         votesAmount % 10 === 4
      ) {
         setLabel("głosy");
      } else {
         setLabel("głosów");
      }
   }, [votes]);

   return (
      <>
         <div className="contest-tile">
            <div
               className="contest-tile__category"
               style={{ backgroundColor: categoryColor }}
            >
               {category}
            </div>
            <div className="contest-tile__thumbnail-wrapper">
               <div
                  className="contest-tile__thumbnail"
                  style={{ backgroundImage: `url(${thumbnail})` }}
               />
               <div className="contest-tile__votes-container">
                  <div className="contest-tile__votes-number">
                     {votesAmount}
                  </div>
                  <div className="contest-tile__votes-label">{label}</div>
               </div>
            </div>
            <div className="contest-tile__right-content">
               <Link to={link}>
                  <h3 className="contest-tile__title">{title}</h3>
               </Link>
               <div className="contest-tile__desc">{desc}</div>
               <div
                  className={classNames("contest-tile__bottom-row", {
                     ["contest-tile__bottom-row--missing-date"]:
                        !dateStart && !dateEnd,
                  })}
               >
                  {dateStart && dateEnd && (
                     <div className="contest-tile__date">
                        Głosowanie od <strong>{dateStart}</strong> do{" "}
                        <strong>{dateEnd}</strong>
                     </div>
                  )}
                  {status !== "Zakończony" && is_between && (
                     <button
                        type="button"
                        onClick={() => setIsPopupVisible(true)}
                        className="button button--small contest-tile__button"
                     >
                        Oddaj głos
                     </button>
                  )}
                  <Link
                     to={link}
                     className="button button--small contest-tile__button"
                  >
                     Więcej
                  </Link>
               </div>
            </div>
         </div>
         {status !== "Zakończony" && is_between && (
            <VotePopup
               visible={isPopupVisible}
               onClose={closePopup}
               pageId={postId}
               pageName={title}
            />
         )}
      </>
   );
};

export default ContestTile;
